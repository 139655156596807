var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "form-a-company-not-found" } },
    [
      _c("slide-heading", {
        attrs: {
          heading:
            "This paperwork is a mystery our guide can’t quite solve yet.",
        },
      }),
      _vm._m(0),
      _c(
        "b-button",
        {
          staticClass: "float-right",
          attrs: { variant: "primary", "aria-label": "continue button" },
          on: {
            click: function ($event) {
              return _vm.$emit("next-slide")
            },
          },
        },
        [_c("span", [_vm._v("Continue")])]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "description" }, [
      _c("p", [
        _vm._v(
          "\n      Our walk-through guide doesn't offer PDF filing for formation paperwork for this\n      state—either the state requires special online filing, or it’s a complex form that\n      needs additional care.\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }